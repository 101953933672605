.ai__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);


        /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);
}

.ai__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.ai__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.ai__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.ai__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;
    color: #ff8A71;
}
.articlep{
    color: #ff8A71;
    text-decoration: underline;
}

.ai__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 600;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.ai__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .ai__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}