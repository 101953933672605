.main{
    padding: 2rem 6rem;
}
.ai__whatai {
    display: flex;
    flex-direction: column;
    padding: 4rem 4rem;
    background: var(--color-footer);


        /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 7%, rgba(30, 29, 28, 1) 94%);
    
}

.ai__whatai-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.ai__whatai-feature .ai__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.ai__whatai-feature .ai__features-container_feature-text {
    max-width: 700px;
}

.ai__whatai-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.ai__whatai-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.ai__whatai-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
    text-decoration: underline;
}

.ai__whatai-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.ai__whatai-container .ai__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.ai__whatai-container .ai__features-container_feature-text {
    margin-top: 1rem;
}

.whatpara{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    color: var(--color-text);
}

@media screen and (max-width: 850px) {
    .ai__whatai-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .ai__whatai-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .ai__whatai-feature .ai__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .ai__whatai-feature .ai__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .ai__whatai-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 490px) {
    /* Customizing feature component as per needs */
    .ai__whatai-container .ai__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
        
    }
    .whatpara{
        font-size: 12px;
        margin-left: -7rem;
    }
}