.ai__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ai__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 2.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.img{
    max-width:150px;
}

.gradient__text__cta{
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
    text-align: center;
    margin-top: 30px;
    margin-bottom: -10px;
    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}