* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  body {
    background: black;
  }
  
  a {
    color: unset;
    text-decoration: none;
  }
  .gradient_bg{
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 0% 0%, rgba(20, 20, 20, 1) 20%, rgba(0, 0, 0, 1) 80%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 0% 0%, rgba(20, 20, 20, 1) 20%, rgba(0, 0, 0, 1) 80%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 0% 0%, rgba(20, 20, 20, 1) 20%, rgba(0, 0, 0, 1) 80%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 0% 0%, rgba(20, 20, 20, 1) 20%, rgba(0, 0, 0, 1) 80%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 0% 0%, rgba(20, 20, 20, 1) 20%, rgb(0, 0, 0) 80%);
  }


.section_padding {
  padding: 4rem 6rem;
}

.section_margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 0rem -5rem;
    padding: 2rem 2rem;
  }  
}