.ai__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;

    background: black;
}

.ai__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.ai__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.ai__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.ai__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.ai__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.ai__footer-links div {
    width: 250px;
    margin: 1rem;
}

.ai__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.ai__footer-links_logo img {
    max-width: 100%;
    margin-top: -6rem;
    margin-bottom: -4rem;
    margin-left:-3rem;
}

.ai__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.ai__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.ai__footer-links_div h4 {
    font-size: 18px;
    line-height: 17px;
    font-family: var(--font-family);
    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;


    margin-bottom: 0.9rem;
}

.ai__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.ai__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.ai__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .ai__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .ai__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .ai__footer-links div {
        margin: 1rem 0;
    }

    .ai__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .ai__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}